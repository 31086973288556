
import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_TOKEN_AGENCY } from '../App/constants'
import { getTokenAgencySuccess, getTokenAgencyError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'
// const serverPath = `https://ver-client.tgd-test.site`

/**
 * Github repos request/response handler
 */
export function * getToken ({ name, password, token, agencyEntity, logo, email, autorisation, trainings }) {

  const getTokenUrl = `${serverPath}/api/agencies/gettoken?agencyname=${name}&agencykey=${name}&expirationDate=2030-01-01&access=premium&password=${password}&logo=${logo}&notificationEmail=${email}&access_token=${token}`
  const urlTgdApi = `${serverPathTgdApi}/agencies`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')
  headers.append('Authorization', `Bearer ${token}`)
  const body = JSON.stringify({"notificationEmail": email, "password": password,"agencyName": name, "entity": agencyEntity,"autorisation": autorisation, "agencyTrainings": trainings})

  const options = {
    method: 'POST',
    headers,
    body
  }

  console.log(body)
  try {
    if (isB2VM) {
      const { token } = yield call(request, urlTgdApi, options)
      yield put(getTokenAgencySuccess(token))
    } else {
      const { token } = yield call(request, getTokenUrl, { method: 'GET' })
      yield put(getTokenAgencySuccess(token))
    }
  } catch (err) {
    yield put(getTokenAgencyError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * getTokenSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_TOKEN_AGENCY, getToken)
}
